<template>
  <div
    v-if="$isNotNullOrUndefined(content.headline)"
    :id="content.key"
    class="container a-headline"
  >
    <div class="row">
      <div class="col-12">
        <h1 class="h1 mb-0 text-uppercase" :class="`text-${textAlign}`">
          {{ content.headline }}
        </h1>
      </div>
    </div>
  </div>
</template>
    
<script>
import contentMixin from '@/mixins/contentMixin';

export default {
  name: 'Headline',	
  mixins: [contentMixin],
  computed: {
    textAlign () {
      return this.content.nzSettingsAlignHeadline?.toLowerCase() ?? 'left'
    }
  }
}
</script>
